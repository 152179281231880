@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Sedan+SC&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
/* blog font */
@import url('https://fonts.googleapis.com/css2?family=Devonshire&family=Federant&family=Love+Light&family=Marck+Script&family=New+Tegomin&family=Playball&display=swap');
/* main font */
@import url('https://fonts.googleapis.com/css2?family=Devonshire&family=Federant&family=Junge&family=Love+Light&family=Marck+Script&family=New+Tegomin&family=Playball&display=swap');

body {
  font-family: "Junge", cursive;
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: orange;
}

/* For Firefox */
.scrollbar-orange {
  scrollbar-width: thin;
  scrollbar-color: orange;
}

.scrollbar-orange::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-orange::-webkit-scrollbar-thumb {
  background-color: orange;
}